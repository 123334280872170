<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewReferralSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetFormCustom"
    @change="(val) => $emit('update:is-add-new-referral-sidebar-active', val)"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="floating-closeright d-flex align-items-center justify-contwnt-center p-25" @click="confirmCloseButton">
        <feather-icon icon="XIcon" size="26" stroke="#101828" class="h-auto"/>
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Refer A Star</h5>

        <!-- <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        /> -->
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form autocomplete="off" id="resume-form" class="p-2" @reset.prevent="resetForm">
          <input type="hidden" name="referral_type" value="1" />
          <b-form-group
            v-if="userData.resume_file_path === ''"
            label="Upload Resume"
            label-for="files"
          >
            <file-pond
              key="resume_file_path"
              v-bind:files="resume_file_path"
              v-if="userData.resume_file_path === ''"
              ref="pond"
              name="files[]"
              max-file-size="3MB"
              max-files="1"
              accepted-file-types="application/pdf"
              file-validate-type-label-expected-types="Expects a PDF file"
              allow-multiple="false"
              image-preview-height="120px"
              @addfile="previewFiles"
            />
            <small class="text-warning flex items-center">
              <feather-icon icon="AlertCircleIcon" class="mr-25" />
              File size should be less than 3MB and allowed formats are
              <b>.pdf</b>
            </small>
          </b-form-group>

          <div v-else>
            <div
              class="d-flex align-items-center demo-inline-spacing justify-content-start"
            >
              <div>Uploaded Resume</div>
              <b-button
                pill
                variant="outline-primary"
                @click.stop.prevent="openWindow(userData.resume_file_path)"
                v-b-tooltip.hover.top="'View Resume'"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                pill
                variant="outline-danger"
                @click.stop.prevent="confirmDeleteRecord(userData.hashid)"
                v-b-tooltip.hover.top="'Remove Resume'"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </div>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Candidate Name"
            rules="required"
          >
            <b-form-group label="Candidate Name" label-for="candidate-name">
              <b-form-input
                id="candidate-name"
                v-model="userData.name"
                name="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                name="email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact -->
          <validation-provider
            #default="validationContext"
            name="Mobile Number"
            rules="required"
          >
            <b-form-group label="Mobile Number" label-for="mobile-number">
              <b-form-input
                id="mobile-number"
                v-model="userData.mobile_number"
                name="mobile_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Position" label-for="position">
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="designationOptions"
              :reduce="(label) => label.code"
              label="label"
              :clearable="false"
              :searchable="true"
              input-id="designation"
              value.sync="role"
              placeholder="Select Position.."
            />
          </b-form-group>
          <b-form-group
            label="Change Status"
            label-for="change-status"
            v-if="$can('Zircly Admin')"
          >
            <v-select
              v-model="userData.referral_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(label) => label.code"
              label="label"
              :clearable="false"
              :searchable="false"
              input-id="change-status"
              placeholder="Select Status.."
            />
          </b-form-group>
          <b-form-group
            label="Referral Reject Reason"
            label-for="referral_reject_reason"
            v-if="userData.referral_status == 2 && $can('Zircly Admin')"
          >
            <b-form-textarea
              id="referral_reject_reason"
              v-model="userData.referral_reject_reason"
              name="referral_reject_reason"
              placeholder="Referral Reject Reason"
            />
          </b-form-group>
          <b-form-group
            label="How do you know this candidate?"
            label-for="determine"
          >
            <v-select
              v-model="userData.determine"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="determineOption"
              :clearable="false"
              :searchable="false"
              :reduce="(label) => label.code"
              name="determine"
            />
          </b-form-group>

          <b-form-group
            label="Why do you think this candidate is a good fit for this role?"
            label-for="recommendation"
          >
            <b-form-textarea
              id="recommendation"
              v-model="userData.recommendation"
              name="recommendation"
              placeholder="Recommendation"
            />
          </b-form-group>

          <b-form-group v-for="(item, index) in customFields" :key="item.id">
            <!-- #default="{ errors }" -->
            <validation-provider
              :name="item.name"
              rules=""
              #default="validationContext"
            >
              <b-form-group
                v-if="item.type == 'number'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'text'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="text"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'url'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="url"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'rating'"
                :label="item.label"
                label-for="input-1"
              >
                <div class="d-flex align-items-center">
                  <star-rating
                    v-model="userData.get_customfields_data['field_' + item.id]"
                    :show-rating="false"
                    :star-size="30"
                  ></star-rating>
                  <span
                    v-if="userData.get_customfields_data['field_' + item.id]"
                    :class="`rating-btn btn ${
                      ratingdescription[
                        userData.get_customfields_data['field_' + item.id]
                      ].class
                    }`"
                  >
                    {{
                      ratingdescription[
                        userData.get_customfields_data["field_" + item.id]
                      ].text
                    }}</span
                  >
                  <input
                    type="hidden"
                    v-model="userData.get_customfields_data['field_' + item.id]"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                  />
                </div>
              </b-form-group>

              <b-form-group
                v-if="item.type == 'password'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'textarea'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-textarea
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  trim
                  :placeholder="item.label"
                />
              </b-form-group>
              <b-form-group
                v-if="item.type == 'date'"
                :label="item.label"
                label-for="input-1"
              >
                <flat-pickr
                  :config="{ dateFormat: 'Y-m-d' }"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  class="form-control"
                  placeholder="Select Date.."
                />
              </b-form-group>

              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="validationForm"
              :disabled="is_loading"
            >
              {{ referralId ? "Update" : "Submit" }}
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button> -->
          </div>
        </b-form>
      </validation-observer>
      <b-overlay :show="is_loading" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import StarRating from "vue-star-rating";
import axios from "@axios";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    FilePond,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "isAddNewReferralSidebarActive",
    event: "update:is-add-new-referral-sidebar-active",
  },
  props: {
    isAddNewReferralSidebarActive: {
      type: Boolean,
      required: true,
    },
    referralId: {
      type: String,
      required: false,
      default: "",
    },
    userData: {
      type: Object,
      required: false,
      default() {
        return {
          get_customfields_data: [],
        };
      },
    },
    designationOptions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      is_loading: false,
      required,
      alphaNum,
      email,
      customFields: [],
      resume_file_path: [],
      determineOption: [
        {
          code: "Worked together in the past",
          label: "Worked together in the past",
        },
        {
          code: "Socially connected",
          label: "Socially connected",
        },
        {
          code: "Relative",
          label: "Relative",
        },
        {
          code: "Ex Employee",
          label: "Ex Employee",
        },
        {
          code: "Others",
          label: "Others",
        },
      ],
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      defaultForm: {
        name: "",
        email: "",
        mobile_number: "",
        position: "",
        resume_file_path: "",
        get_customfields_data: [],
      },
      statusOptions: [
        { label: "Pending", code: 0 },
        { label: "Converted", code: 1 },
        { label: "Rejected", code: 2 },
      ],
    };
  },
  setup(props) {
    const blankUserData = {
      name: "",
      email: "",
      mobile_number: "",
      determine: "",
      get_customfields_data: [],
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      // userData,

      refFormObserver,
      getValidationState,
      resetForm,
      FilePond,
    };
  },
  computed: {
    hasChanged() {
      return Object.keys(this.userData).some(
        (field) => this.userData[field] !== this.defaultForm[field]
      );
    },
  },
  created() {
    this.getCustomFields();
    this.userData.get_customfields_data = [];
  },
  methods: {
    confirmDeleteRecord(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeResume(uuid);
        }
      });
    },
    removeResume(uuid) {
      let data = {};
      data.url = "candidate/resume/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Resume Deleted",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: "Resume Deleted Successfully.",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Resume Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Resume Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.userData.resume_file_path = "";
          this.resume_file_path = [];
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Remove Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Remove Deletion failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.userData.get_customfields_data = [];
          this.resetFormCustom();
          this.$emit("update:is-add-new-referral-sidebar-active", false);
        }
      });
    },
    openWindow(link) {
      window.open(link);
    },
    previewFiles(error, file) {
      console.log(file);
      const self = this;
      const formData = new FormData();

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      //check when save and assign interview btn clicked
      if (keys.length == 0) {
        return;
      }
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      //formData.append('resume',file)
      axios
        .post("/parse-resume", formData, config)
        .then((res) => {
          if (res.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Zircly able to extract details from the Resume",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zircly able to extract details from the Resume',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            var populate_data = res.data.data;
            console.log(populate_data);
            for (var k in populate_data) {
              console.log(k, populate_data[k]);
              this.userData[k] = populate_data[k];
            }
          }
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to Parse Resume",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to Parse Resume',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-referrals/getCustomFields")
        .then((res) => {
          if (res.data.success) {
            self.customFields = res.data.data;
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Custom Fields Getting Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Custom Fields Getting Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    resetFormCustom() {
      this.resetForm();
      console.log("resetFormCustom");
      this.userData.resume_file_path = "";
      this.resume_file_path = [];
      this.userData.get_customfields_data = [];
      const pondIds = [];
      if (this.$refs.pond && this.$refs.pond.getFiles().length === 0) {
        const keys = Object.keys(this.$refs.pond.getFiles());
        const files = this.$refs.pond.getFiles();
        for (let i = 0; i < keys.length; i += 1) {
          pondIds.push(files[i].id);
        }
        this.$refs.pond.removeFiles(pondIds);
      }
    },
    validationForm() {
      const self = this;
      this.is_loading = true;
      // if (
      //   self.userData.resume_file_path === "" &&
      //   this.$refs.pond.getFiles().length === 0
      // ) {
      //   self.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: "Resume Needed",
      //       icon: "BellIcon",
      //       variant: "danger",
      //       text: "Kindly upload a resume",
      //     },
      //   });
      //   this.is_loading = false;
      //   return false;
      // }
      return new Promise((resolve, reject) => {
        const formData = new FormData(document.getElementById("resume-form"));
        formData.delete("files[]");

        if (self.userData.resume_file_path === "") {
          const files = self.$refs.pond.getFiles();
          files.forEach((file, index) => {
            formData.append(`files[${index}]`, file.file);
          });
        }
        const userData = self.userData;
        const propertiesToAppend = [
          "role",
          "determine",
          "referral_status",
          "skills",
        ];

        for (const property of propertiesToAppend) {
          if (userData[property]) {
            formData.append(property, userData[property]);
          }
        }

        if (self.referralId !== "") {
          formData.append("_method", "PATCH");
        }

        self.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            const dispatchAction =
              self.referralId === ""
                ? "app-referrals/addReferral"
                : "app-referrals/updateReferral";
            const dispatchPayload =
              self.referralId === ""
                ? formData
                : { id: self.referralId, data: formData };

            self.$store
              .dispatch(dispatchAction, dispatchPayload)
              .then((res) => {
                const successTitle =
                  self.referralId === ""
                    ? "Referral Added"
                    : "Referral Updated";
                const successText =
                  self.referralId === "" ? res.data.message : res.data.message;
                const errorTitle =
                  self.referralId === ""
                    ? "Oops! Referral Adding Failed"
                    : "Oops! Referral Updating Failed";

                if (res.data.success) {
                  this.is_loading = false;
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: successTitle,
                  //     icon: "BellIcon",
                  //     variant: "success",
                  //     text: successText,
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: successTitle,
                icon: 'BellIcon',
                variant: 'success',
                text: successText,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

                  self.$emit("refetch-data");
                  self.$emit(
                    "update:is-add-new-referral-sidebar-active",
                    false
                  );
                } else {
                  this.is_loading = false;
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: errorTitle,
                  //     icon: "BellIcon",
                  //     variant: "danger",
                  //     text: res.data.message,
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: errorTitle,
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              })
              .catch((error) => {
                this.is_loading = false;
                const errorTitle =
                  self.referralId === ""
                    ? "Oops! Referral Adding Failed"
                    : "Oops! Referral Updating Failed";
                // self.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: errorTitle,
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: error,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: errorTitle,
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              });
            this.is_loading = false;
          } else {
            this.is_loading = false;
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
